<template>
  <div style="max-width: 500px; margin: auto">
    <ion-list lines="none">
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{
          $t("profile.mainInfo")
        }}</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-icon slot="start" :icon="briefcaseOutline" style=""></ion-icon>
        <ion-label position="fixed"> {{ $t("profile.work.work") }} </ion-label>
        <ion-chip>
          <ion-label>{{
            $t("profile.work.options." + profile.work)
          }}</ion-label>
        </ion-chip>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" :icon="schoolOutline" style=""></ion-icon>
        <ion-label position="fixed">{{
          $t("profile.education.education")
        }}</ion-label>
        <ion-chip>
          <ion-label>{{
            $t("profile.education.options." + profile.education)
          }}</ion-label>
        </ion-chip>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" :icon="locationOutline" style=""></ion-icon>
        <ion-label position="fixed">{{
          $t("profile.location.location")
        }}</ion-label>
        <ion-chip>
          <ion-label>{{
            $t("profile.location.options." + profile.location)
          }}</ion-label>
        </ion-chip>
      </ion-item>
    </ion-list>

    <ion-list v-if="profile.hobbies || profile.customHobbies">
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{
          $t("profile.hobbies.hobbies")
        }}</ion-label>
      </ion-list-header>
      <p>
        <ion-chip v-for="(item, index) in profile.hobbies" :key="index">
          <ion-label>{{ $t("profile.hobbies.options." + item) }}</ion-label>
        </ion-chip>
        <ion-chip v-for="(item, index) in profile.customHobbies" :key="index">
          <ion-label>{{ item }}</ion-label>
        </ion-chip>
      </p>
    </ion-list>

    <ion-list v-if="profile.languages">
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{
          $t("profile.languages.languages")
        }}</ion-label>
      </ion-list-header>
      <p>
        <ion-chip v-for="(item, index) in profile.languages" :key="index">
          <ion-label>{{ $t("profile.languages.options." + item) }}</ion-label>
        </ion-chip>
      </p>
    </ion-list>

    <ion-list lines="none" v-if="profile.religion || profile.smoking">
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">Ďalšie informácie</ion-label>
      </ion-list-header>
      <ion-item v-if="profile.smoking">
        <ion-icon slot="start" :icon="briefcaseOutline" style=""></ion-icon>
        <ion-label position="fixed">
          {{ $t("profile.smoking.smoking") }}</ion-label
        >
        <ion-chip>
          <ion-label>{{
            $t("profile.smoking.options." + profile.smoking)
          }}</ion-label>
        </ion-chip>
      </ion-item>
      <ion-item v-if="profile.religion">
        <ion-icon slot="start" :icon="schoolOutline" style=""></ion-icon>
        <ion-label position="fixed">{{
          $t("profile.religion.religion")
        }}</ion-label>
        <ion-chip>
          <ion-label>{{
            $t("profile.religion.options." + profile.religion)
          }}</ion-label>
        </ion-chip>
      </ion-item>
    </ion-list>

    <ion-list lines="none" v-if="profile.photos">
      <ion-list-header lines="full" mode="ios">
        <ion-label class="sectionHeader">{{ $t("profile.photos") }}</ion-label>
      </ion-list-header>
      <ion-grid>
        <ion-row>
          <ion-col
            v-for="(photoUrl, index) in profile.photos"
            :key="index"
            @click="openGalleryPhoto(index)"
            size="4"
            style="text-align: center; padding: 0px"
          >
            <ion-card
              v-if="photoUrl"
              style="height: 133px; margin: 2px; border-radius: 25px"
            >
              <ion-img
                style="
                  cursor: pointer;
                  height: 100%;
                  object-fit: cover;
                  width: 100%;
                "
                :src="photoUrl"
              ></ion-img>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-list>
  </div>
</template>

<script lang="ts">
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonImg,
  IonIcon,
  IonChip,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  modalController,
} from "@ionic/vue";
import {
  informationCircleOutline,
  briefcaseOutline,
  locationOutline,
  homeOutline,
  schoolOutline,
  personOutline,
  cameraOutline,
  imageOutline,
  trash,
  imagesOutline,
  close,
  languageOutline,
  addOutline,
} from "ionicons/icons";

import PhotosShowModal from "@/components/PhotosShowModal.vue";

export default {
  name: "ProfileBody",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonImg,
    IonIcon,
    IonChip,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
  },
  props: {
    profile: Object,
    allPhotos: Array,
  },
  setup(props: any) {
    const openGalleryPhoto = async (index: number) => {
      let photosIndex = index;
      if (props.profile.profilePhoto) {
        photosIndex = photosIndex + 1;
      }
      const photosToShow: Array<any> = [];
      props.allPhotos.forEach((photoUrl: string) => {
        photosToShow.push({ type: "image", url: photoUrl });
      });
      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: photosToShow,
          index: photosIndex,
        },
      });
      return modal.present();
    };

    return {
      openGalleryPhoto,
      informationCircleOutline,
      briefcaseOutline,
      locationOutline,
      homeOutline,
      schoolOutline,
      personOutline,
      cameraOutline,
      imageOutline,
      trash,
      imagesOutline,
      close,
      languageOutline,
      addOutline,
    };
  },
};
</script>

<style scoped>
ion-list {
  border-radius: 25px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
}

ion-list p {
  padding: 0.5rem;
  margin: 0;
}

ion-list-header {
  padding-left: 1rem;
  /* padding: 0.5rem; */
}

ion-chip {
  margin-left: 0;
  pointer-events: none;
  cursor: default;
}

ion-list-header ion-label.sectionHeader {
  margin-top: 1rem;
}

ion-item {
  --padding-start: 1rem;
  background: transparent;
}

ion-list ion-item {
  --background: transprent;
  background: transparent;
}
</style>