
import { IonIcon, IonAvatar, IonCard, modalController } from "@ionic/vue";

import { personOutline } from "ionicons/icons";

import PhotosShowModal from "@/components/PhotosShowModal.vue";

export default {
  name: "ProfileHeader",
  components: {
    IonCard,
    IonAvatar,
    IonIcon,
  },
  props: {
    profile: Object,
    allPhotos: Array,
  },
  setup(props: any) {
    const openHeaderPhoto = async (photoName: string) => {
      let index;
      if (photoName == "profile") {
        if (!props.profile.profilePhoto) {
          return;
        }
        index = 0;
      } else if (photoName == "title") {
        if (!props.profile.titlePhoto) {
          return;
        }
        index = props.allPhotos.length - 1;
      } else {
        return;
      }
      const photosToShow: Array<any> = [];
      props.allPhotos.forEach((photoUrl: string) => {
        photosToShow.push({ type: "image", url: photoUrl });
      });

      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: photosToShow,
          index: index,
        },
      });
      return modal.present();
    };

    return {
      openHeaderPhoto,
      personOutline,
    };
  },
};
