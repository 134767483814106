<template>
  <div>
    <div
      class="accountTabHeader"
      style="text-align: center; max-width: 500px; margin: auto"
    >
      <div>
        <ion-card
          @click="openHeaderPhoto('title')"
          style="
            position: revert;
            text-align: center;
            background: rgb(117, 117, 117);
            background: linear-gradient(
              180deg,
              rgba(117, 117, 117, 1) 0%,
              rgba(18, 18, 18, 1) 100%
            );
            margin: auto;
            height: 180px;
            width: 100%;
            margin-top: 15px;
            border-radius: 5px 5px 45px 45px;
          "
        >
          <img
            v-if="profile.titlePhoto"
            :src="profile.titlePhoto"
            style="cursor: pointer"
          />
        </ion-card>
      </div>
      <ion-avatar
        style="
          -webkit-box-shadow: 0px 0px 8px 5px #939393;
          -moz-box-shadow: 0px 0px 8px 5px #939393;
          box-shadow: 0px 0px 8px 5px #939393;
          background-color: #aaa;
          position: relative;
          margin: auto;
          height: 150px;
          width: 150px;
          margin-top: -4rem;
        "
        @click="openHeaderPhoto('profile')"
      >
        <img
          v-if="profile.profilePhoto"
          :src="profile.profilePhoto"
          style="cursor: pointer"
        />
        <div v-else style="height: 100%; width: 100%; border-radius: 50%">
          <ion-icon
            size="large"
            :icon="personOutline"
            style="height: 100%; color: ghostwhite"
          />
        </div>
      </ion-avatar>
      <h1 id="myName" style="font-weight: bold">{{ profile.name }}</h1>
      <p v-if="profile.bio">
        <q>{{ profile.bio }}</q>
      </p>
    </div>
    <div style="max-width: 500px; margin: auto"></div>
  </div>
</template>

<script lang="ts">
import { IonIcon, IonAvatar, IonCard, modalController } from "@ionic/vue";

import { personOutline } from "ionicons/icons";

import PhotosShowModal from "@/components/PhotosShowModal.vue";

export default {
  name: "ProfileHeader",
  components: {
    IonCard,
    IonAvatar,
    IonIcon,
  },
  props: {
    profile: Object,
    allPhotos: Array,
  },
  setup(props: any) {
    const openHeaderPhoto = async (photoName: string) => {
      let index;
      if (photoName == "profile") {
        if (!props.profile.profilePhoto) {
          return;
        }
        index = 0;
      } else if (photoName == "title") {
        if (!props.profile.titlePhoto) {
          return;
        }
        index = props.allPhotos.length - 1;
      } else {
        return;
      }
      const photosToShow: Array<any> = [];
      props.allPhotos.forEach((photoUrl: string) => {
        photosToShow.push({ type: "image", url: photoUrl });
      });

      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: photosToShow,
          index: index,
        },
      });
      return modal.present();
    };

    return {
      openHeaderPhoto,
      personOutline,
    };
  },
};
</script>
