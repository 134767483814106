
import { defineComponent, onMounted, ref, nextTick } from "vue";
import {
  IonIcon,
  IonFab,
  IonFabButton,
  IonSlides,
  IonSlide,
  modalController,
} from "@ionic/vue";

import {
  closeOutline,
  arrowBackOutline,
  arrowForwardOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "PhotoModal",
  components: {
    IonSlides,
    IonSlide,
    IonFab,
    IonFabButton,

    IonIcon,
  },
  props: {
    photos: { type: Object },
    index: { type: Number },
  },
  setup(props: any) {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const photoSlides = ref<any>(""); // sometimes is null
    const sliderShow = ref(false);
    const slideOpts = {
      initialSlide: props.index,
      speed: 300,
    };

    onMounted(async () => {
      await nextTick();
      sliderShow.value = true;
      setTimeout(async () => {
        photoSlides.value = document.getElementById("photosShowSlides");
        await photoSlides?.value?.update();
      }, 100);
    });

    const nextSlide = async () => {
      photoSlides.value = document.getElementById("photosShowSlides");
      await photoSlides.value.slideNext();
      await photoSlides.value.updateAutoHeight();
    };
    const prevSlide = async () => {
      photoSlides.value = document.getElementById("photosShowSlides");
      await photoSlides?.value?.slidePrev();
      await photoSlides.value.updateAutoHeight();
    };

    return {
      // allPhotos,
      sliderShow,
      photoSlides,
      slideOpts,
      nextSlide,
      prevSlide,
      closeModal,

      //ICONS
      closeOutline,
      arrowBackOutline,
      arrowForwardOutline,
    };
  },
});
