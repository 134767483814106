<template>
  <div style="max-width: 500px; margin: auto">
    <div style="text-align: center">
      <div>
        <ion-card
          style="
            position: revert;
            text-align: center;
            margin: auto;
            height: 180px;
            width: 100%;
            margin-top: 15px;
            border-radius: 5px 5px 45px 45px;
          "
        >
        </ion-card>
      </div>
      <ion-avatar
        style="
          position: relative;
          margin: auto;
          height: 150px;
          width: 150px;
          margin-top: -4rem;
        "
      >
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <h1>
        <ion-skeleton-text
          animated
          style="width: 30%; margin: auto; line-height: 20px"
        ></ion-skeleton-text>
      </h1>
      <p>
        <ion-skeleton-text
          animated
          style="width: 60%; margin: auto"
        ></ion-skeleton-text>
      </p>
    </div>
    <ion-list lines="none" style="border-radius: 20px">
      <ion-list-header lines="full">
        <ion-label
          ><ion-skeleton-text
            animated
            style="width: 30%; line-height: 18px"
          ></ion-skeleton-text
        ></ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
</template>

<script>
import {
  IonCard,
  IonItem,
  IonList,
  IonListHeader,
  IonLabel,
  IonAvatar,
  IonSkeletonText,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
    IonLabel,
    IonAvatar,
    IonSkeletonText,
  },
});
</script>

<style scoped>
ion-skeleton-text {
  border-radius: 20px;
}

ion-avatar ion-skeleton-text {
  border-radius: 50%;
}
</style>
