
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonImg,
  IonIcon,
  IonChip,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  modalController,
} from "@ionic/vue";
import {
  informationCircleOutline,
  briefcaseOutline,
  locationOutline,
  homeOutline,
  schoolOutline,
  personOutline,
  cameraOutline,
  imageOutline,
  trash,
  imagesOutline,
  close,
  languageOutline,
  addOutline,
} from "ionicons/icons";

import PhotosShowModal from "@/components/PhotosShowModal.vue";

export default {
  name: "ProfileBody",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonImg,
    IonIcon,
    IonChip,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
  },
  props: {
    profile: Object,
    allPhotos: Array,
  },
  setup(props: any) {
    const openGalleryPhoto = async (index: number) => {
      let photosIndex = index;
      if (props.profile.profilePhoto) {
        photosIndex = photosIndex + 1;
      }
      const photosToShow: Array<any> = [];
      props.allPhotos.forEach((photoUrl: string) => {
        photosToShow.push({ type: "image", url: photoUrl });
      });
      const modal = await modalController.create({
        component: PhotosShowModal,
        cssClass: "photoModalClass",
        componentProps: {
          photos: photosToShow,
          index: photosIndex,
        },
      });
      return modal.present();
    };

    return {
      openGalleryPhoto,
      informationCircleOutline,
      briefcaseOutline,
      locationOutline,
      homeOutline,
      schoolOutline,
      personOutline,
      cameraOutline,
      imageOutline,
      trash,
      imagesOutline,
      close,
      languageOutline,
      addOutline,
    };
  },
};
