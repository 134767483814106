<template>
  <div style="--backdrop-opacity: 0.9; height: 100%">
    <ion-fab horizontal="center" vertical="top" slot="fixed">
      <ion-fab-button @click="closeModal" color="dark" style="opacity: 0.5">
        <ion-icon size="large" :icon="closeOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-fab horizontal="start" vertical="center" slot="fixed">
      <ion-fab-button @click="prevSlide()" color="dark" style="opacity: 0.5">
        <ion-icon :icon="arrowBackOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-fab horizontal="end" vertical="center" slot="fixed">
      <ion-fab-button @click="nextSlide()" color="dark" style="opacity: 0.5">
        <ion-icon :icon="arrowForwardOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-slides
      id="photosShowSlides"
      v-show="sliderShow"
      ref="photoSlides"
      pager="true"
      :options="slideOpts"
      style="height: inherit; --bullet-background-active: red"
    >
      <!-- <ion-slide v-for="(photo) in photos" :key="photo.msgID"> -->
      <ion-slide v-for="(media, ind) in photos" :key="ind">
        <img
          v-if="media.type == 'image'"
          style="width: 100%; height: auto"
          :src="media.url"
        />
        <div
          v-else
          style="height: 100%; width: 100%; display: flex; align-items: center"
        >
          <video style="width: 100%; height: 80%; max-height: 100%" controls>
            <source :src="media.url" type="video/mp4" />
          </video>
        </div>
      </ion-slide>
    </ion-slides>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, nextTick } from "vue";
import {
  IonIcon,
  IonFab,
  IonFabButton,
  IonSlides,
  IonSlide,
  modalController,
} from "@ionic/vue";

import {
  closeOutline,
  arrowBackOutline,
  arrowForwardOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "PhotoModal",
  components: {
    IonSlides,
    IonSlide,
    IonFab,
    IonFabButton,

    IonIcon,
  },
  props: {
    photos: { type: Object },
    index: { type: Number },
  },
  setup(props: any) {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const photoSlides = ref<any>(""); // sometimes is null
    const sliderShow = ref(false);
    const slideOpts = {
      initialSlide: props.index,
      speed: 300,
    };

    onMounted(async () => {
      await nextTick();
      sliderShow.value = true;
      setTimeout(async () => {
        photoSlides.value = document.getElementById("photosShowSlides");
        await photoSlides?.value?.update();
      }, 100);
    });

    const nextSlide = async () => {
      photoSlides.value = document.getElementById("photosShowSlides");
      await photoSlides.value.slideNext();
      await photoSlides.value.updateAutoHeight();
    };
    const prevSlide = async () => {
      photoSlides.value = document.getElementById("photosShowSlides");
      await photoSlides?.value?.slidePrev();
      await photoSlides.value.updateAutoHeight();
    };

    return {
      // allPhotos,
      sliderShow,
      photoSlides,
      slideOpts,
      nextSlide,
      prevSlide,
      closeModal,

      //ICONS
      closeOutline,
      arrowBackOutline,
      arrowForwardOutline,
    };
  },
});
</script>

<style>
.photoModalClass .modal-wrapper {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background: rgba(0, 0, 0, 0.9);
  opacity: 1 !important;
}

.photoModalClass {
  --backdrop-opacity: 0.8 !important;
}
</style>